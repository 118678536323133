import React from 'react'
import { useSelector } from 'react-redux';

const Error = () => {
    let message = useSelector((state)=>state.value);
  return (
    <div style={{color:'red', padding:'1rem'}}>{message.error}</div>
  )
}

export default Error