import {
  Avatar,
  Box,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { MdLogout, MdOutlineManageAccounts, MdOutlineWorkOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const UserProfile = ({
  firstName,
  lastName,
  imgSrc,
  occupation,
  location,
  friends,
  impressions,
  viewedProfile,
}) => {
  const borderColor = useColorModeValue("blackAlpha.300", "whiteAlpha.300");
  const text = useColorModeValue("black", "white");
  const text1 = useColorModeValue("blackAlpha.700", "whiteAlpha.700");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function setLogOut() {
    navigate('/login')
    dispatch({
      type: "resetUser",
    });
  }

  let friendCount = "";

  if (!friends) {
    friendCount = 0;
  } else {
    friendCount = Object.entries(friends).length;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      p={"1rem"}
      boxShadow={"md"}
      borderRadius={"10px"}
      pos={{lg:"sticky", base:"unset"}}
      h={"fit-content"}
      top={"112px"}
      order={{lg:"unset", base:"1"}}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        borderBottom={"2px"}
        borderColor={borderColor}
        gap={"0.5rem"}
        py={"1rem"}
      >
        <Avatar src={imgSrc} height={"50px"} width={"50px"} />
        <Box width={"full"}>
          <Text fontSize={"lg"} fontWeight={"700"}>
            {firstName} {lastName}
          </Text>
          <Text fontSize={"sm"} fontWeight={"500"} color={text1}>
            {friendCount} Friends
          </Text>
        </Box>
        <MdLogout size={"2.5em"} onClick={setLogOut} cursor={'pointer'} />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        borderBottom={"2px"}
        borderColor={borderColor}
        gap={"0.5rem"}
        py={"1rem"}
      >
        <HStack>
          <IoLocationOutline size={"1.5em"} />
          <Text fontSize={"sm"} color={text}>
            {location}
          </Text>
        </HStack>

        <HStack>
          <MdOutlineWorkOutline size={"1.5em"} />
          <Text fontSize={"sm"} color={text}>
            {occupation}
          </Text>
        </HStack>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        borderBottom={"2px"}
        borderColor={borderColor}
        gap={"0.5rem"}
        py={"1rem"}
      >
        <HStack justifyContent={"space-between"}>
          <Text fontSize={"sm"} color={text}>
            Who is viewed your profile?
          </Text>
          <Text fontSize={"sm"}>{viewedProfile}</Text>
        </HStack>
        <HStack justifyContent={"space-between"}>
          <Text fontSize={"sm"} color={text}>
            Impressions of your post
          </Text>
          <Text fontSize={"sm"}>{impressions}</Text>
        </HStack>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"0.5rem"}
        paddingTop={"1rem"}
      >
        <Text fontSize={"md"} fontWeight={"500"}>
          Social Profiles
        </Text>
        <Box display={"flex"} gap={"0.5rem"} placeItems={"center"}>
          <AiFillLinkedin size={"2.5em"} opacity={"0.7"} />
          <Box width={"full"}>
            <Text fontSize={"sm"} fontWeight={"500"}>
              {firstName} {lastName}
            </Text>
            <Text fontSize={"sm"} fontWeight={"500"} color={text1}>
              {friendCount} Friends
            </Text>
          </Box>
          <MdOutlineManageAccounts size={"2em"} />
        </Box>
        <Box display={"flex"} placeItems={"center"} gap={"0.5rem"}>
          <AiOutlineTwitter size={"2.5em"} opacity={"0.7"} />
          <Box width={"full"}>
            <Text fontSize={"sm"} fontWeight={"500"}>
              {firstName} {lastName}
            </Text>
            <Text fontSize={"sm"} fontWeight={"500"} color={text1}>
              {friendCount} Friends
            </Text>
          </Box>
          <MdOutlineManageAccounts size={"2em"} />
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
