import { HStack, Text, Button, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";

const Nav = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const orange = useColorModeValue("orange.500", "orange.200");
  const nav = useColorModeValue("white", "#1a202c");
  return (
    <HStack
      display={'flex'}
      h={"80px"}
      w={'full'}
      px={{ "2xl": "6rem", md: "2rem", sm:"1rem", base: "0.5rem" }}
      justifyContent={"space-between"}
      boxShadow={'md'}
      position={'relative'}
      pos={'sticky'}
      top={0}
      zIndex={'1'}
      bg={nav}
    >
      <Text
        fontSize={"4xl"}
        color={orange}
        fontWeight={"700"}
        letterSpacing={"2px"}
      >
        LinkLyfe
      </Text>
      <Button onClick={toggleColorMode}>
        {colorMode === 'light' ? <MdDarkMode size={"1.5em"} /> : <MdLightMode size={"1.5em"} />}
      </Button>
    </HStack>
  );
};

export default Nav;
