import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Nav from "../components/Nav";
import Post from "../components/Post";
import { useSelector } from "react-redux";
import { Box, VStack } from "@chakra-ui/react";
import  Loader  from "../components/Loader";

const Friend = () => {
  let user = useSelector((state) => state.value);
  const [posts, setPosts] = useState("");
  let params = useParams();
  useEffect(() => {
    async function logPosts() {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/posts/${params.id}/posts`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      let resp = await response.json();

      setPosts(resp);
    }
    logPosts();
  }, []);

  return (
    <>
      <Nav />
      <Box
        display={{ lg: "grid", base: "flex" }}
        flexDirection={"column"}
        gridTemplateColumns={{"2xl":"1fr 2fr 1fr", lg:"1fr 1.5fr 1fr"}}
        px={{ "2xl": "6rem", md: "2rem", sm:"1rem", base: "0.5rem" }}
        my={"2rem"}
        gap={{ "2xl": "3rem", base:"2rem" }}
      >
        <Box display={{lg:"unset", base:"none"}}></Box>
        <VStack gap={"2rem"}>
          {
          posts==="" ? 
          <Box pos={'absolute'} top={'50vh'}>
<Loader/>
          </Box> :
          posts.map((value) => {
            return (
              <Post
                id={value._id}
                picturePath={value.picturePath}
                description={value.description}
                firstName={value.firstName}
                lastName={value.lastName}
                likes={value.likes}
                userId={value.userId}
                location={value.location}
                occupation={value.occupation}
                userPicturePath={value.userPicturePath}
              />
            );
          })}
        </VStack>
        <Box display={{lg:"unset", base:"none"}}></Box>
      </Box>
    </>
  );
};

export default Friend;
