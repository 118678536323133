import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import UserProfile from "../components/UserProfile";
import CreatePost from "../components/CreatePost";
import FriendList from "../components/FriendList";
import Nav from "../components/Nav";
import { useSelector } from "react-redux";

const User = () => {
  let data = useSelector((state) => state.value.user);

  return (
    <>
      <Nav />
      <Box
        display={{ lg: "grid", base: "flex" }}
        flexDirection={"column"}
        gridTemplateColumns={{"2xl":"1fr 2fr 1fr", lg:"1fr 1.5fr 1fr"}}
        px={{ "2xl": "6rem", md: "2rem", sm:"1rem", base: "0.5rem" }}
        my={"2rem"}
        gap={{ "2xl": "3rem", base:"2rem" }}
      >
        <UserProfile
          firstName={data.firstName}
          lastName={data.lastName}
          imgSrc={data.picturePath}
          location={data.location}
          occupation={data.occupation}
          friends={data.friends}
          viewedProfile={data.viewedProfile}
          impressions={data.impressions}
        />
        <VStack gap={"2rem"} order={{ lg: "unset", base: "3" }}>
          <CreatePost profilePic={data.picturePath} />
        </VStack>
        <FriendList />
      </Box>
    </>
  );
};

export default User;
