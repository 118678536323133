import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer(
  {
    value: {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        location: "",
        occupation: "",
        picturePath: "",
        friends: [],
        _id: "",
      },
      token: "",
    },
    friendList: [],
  },
  {
    saveUser: (state, action) => {
      state.value = action.payload;
    },
    getFriends: (state, action) => {
      state.friendList = action.payload;
    },
    updateFriends: (state, action) => {
      state.value.user.friends = action.payload;
    },
    resetUser: (state) => {
      state.value = {
        user: {
          firstName: "",
          lastName: "",
          email: "",
          location: "",
          occupation: "",
          picturePath: "",
          friends: [],
          _id: "",
        },
        token: "",
      };
      state.friendList = [];
    },
  }
);
