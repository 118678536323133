import {
  Button,
  Input,
  Text,
  VStack,
  useColorModeValue,
  useColorMode,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const borderColor = useColorModeValue("blackAlpha.300", "whiteAlpha.300");
  const orange = useColorModeValue("orange.500", "orange.200");
  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function navigate123() {
    navigate('/')
  }

  function uploadData(e) {
    e.preventDefault();
    const data = new URLSearchParams();
    for (const pair of new FormData(document.getElementById("login-form"))) {
      data.append(pair[0], pair[1]);
    }

    fetch(`${process.env.REACT_APP_API_KEY}/auth/login`, {
      method: "post",
      body: data,
    }).then(async (res) => {
      let resp = await res.json();
      dispatch({
        type: "saveUser",
        payload: resp,
      });
      
      if (resp.error) {
        navigate("/error");
      } else {
        navigate(`/user`);
      }
    });
  }

  return (
    <Box
    w={'full'}
    minH={'100vh'}
    display={'flex'}
    flexDirection={'column'}
    justifyContent={'center'}
    gap={'1rem'}
    pt={'1rem'}
    pb={'2rem'}
  >
      <Button m={"1rem"} onClick={toggleColorMode} w={'fit-content'} alignSelf={'center'}>
        {colorMode === "light" ? (
          <MdDarkMode size={"1.5em"} />
        ) : (
          <MdLightMode size={"1.5em"} />
        )}
      </Button>
      <Text
        fontSize={{ lg: "4xl", base: "2xl" }}
        px={{ lg: "unset", base: "1rem" }}
        textAlign={"center"}
        color={orange}
        fontWeight={"700"}
        letterSpacing={"2px"}
      >
        Welcome <span style={{ color: "#3182CE" }}>Back!</span>
      </Text>
      <form
      id="login-form"
      onSubmit={uploadData}
      >
      <VStack
         width={{ "2xl": "50%", md: "70%", base: "100%" }}
         margin={"auto"}
         p={"2rem"}
         borderRadius={"10px"}
         gap={"1.5rem"}
         boxShadow={{md:"md", base:"unset"}}
      >
        <Input
          variant={"unstyled"}
          px={"1rem"}
          py={"0.5rem"}
          name="email"
          placeholder="Email"
          border={"2px"}
          borderColor={borderColor}
        />
        <Input
          variant={"unstyled"}
          type="password"
          px={"1rem"}
          py={"0.5rem"}
          name="password"
          placeholder="Password"
          border={"2px"}
          borderColor={borderColor}
        />
        <Button type="submit" colorScheme="orange" w={"full"} py={"1.5rem"}>
          LOGIN
        </Button>
        <Button variant={'link'} style={{ fontWeight: "500" }} color={orange} onClick={navigate123}>
          Don't have an account? Register here.
        </Button>
        <Text>© 2023 Subham Chatterjee</Text>
      </VStack>
      </form>
    </Box>
  );
};

export default Login;
