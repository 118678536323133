import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import {
  IoPersonAddOutline,
  IoPersonRemoveOutline,
} from "react-icons/io5";
import { BiComment, BiShareAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLocationOn, MdOutlineWorkOutline } from "react-icons/md";

const Post = ({
  id,
  description,
  picturePath,
  firstName,
  lastName,
  likes,
  userId,
  location,
  occupation,
  userPicturePath
}) => {
  const { colorMode } = useColorMode();
  const [likes1, setLikes1] = useState(likes);
  let user = useSelector((state) => state.value);
  const dispatch = useDispatch();
  const isLiked = Boolean(likes1[user.user._id]);
  let isFriend = user.user.friends.includes(userId);

  const button = useColorModeValue("#e8ecf4", "#282c3c");
  async function like123() {
    await fetch(`${process.env.REACT_APP_API_KEY}/posts/${id}/like`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${user.token}`,
      },
      body: new URLSearchParams({
        userId: `${user.user._id}`,
      }),
    }).then(async (resp) => {
      let response = await resp.json();
      setLikes1(response.likes);
    });
  }
  async function toggleFriend() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}/users/${user.user._id}/${userId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    let resp = await response.json();
    dispatch({
      type: "getFriends",
      payload: resp,
    });
    let abc123 = resp.map(({ _id }) => {
      return _id;
    });
    dispatch({
      type: "updateFriends",
      payload: abc123,
    });
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      w={"full"}
      p={"1rem"}
      boxShadow={"md"}
      borderRadius={"10px"}
      gap={"1rem"}
    >
      <Box
        display={"flex"}
        gap={"0.5rem"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Avatar src={userPicturePath} height={'50px'} width={'50px'}/>
        <Box width={"full"}>
          <Link
            to={`/friend/${userId}`}
            style={{ display: "block", width: "fit-content" }}
          >
            <Text fontSize={"md"} fontWeight={"700"}>
              {firstName} {lastName}
            </Text>
          </Link>

          <Box display={"flex"} gap={"0.5rem"}>
            <HStack justifyContent={"center"} alignItems={"center"}>
              <MdOutlineLocationOn style={{transform:'translateX(40%)'}} size={"1.2em"} />
              <Text fontSize={"sm"}>{location}</Text>
            </HStack>
            <HStack justifyContent={"center"} alignItems={"center"}>
              <MdOutlineWorkOutline size={"1.2em"} style={{transform:'translateX(27%)'}} />
              <Text fontSize={"sm"}>{occupation}</Text>
            </HStack>
          </Box>
        </Box>
        {userId === user.user._id ? (
          ""
        ) : isFriend ? (
          <Button
            variant={"unstyled"}
            borderRadius={"full"}
            bg={button}
            onClick={toggleFriend}
          >
            <IoPersonRemoveOutline size={"1.5em"} style={{ margin: "auto" }} />
          </Button>
        ) : (
          <Button
            variant={"unstyled"}
            borderRadius={"full"}
            bg={button}
            onClick={toggleFriend}
          >
            <IoPersonAddOutline size={"1.5em"} style={{ margin: "auto" }} />
          </Button>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
        <Text fontSize={"sm"}>{description}</Text>
        <Image
          src={picturePath}
          alt="picture"
          height={"30vmax"}
          width={"full"}
          objectFit={"cover"}
          borderRadius={"10px"}
        />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} gap={"1rem"}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Button variant={"unstyled"} onClick={like123}>
              {isLiked && colorMode === "light" ? (
                <AiFillHeart
                  size={"1.5em"}
                  style={{ margin: "auto", color: "red" }}
                />
              ) : isLiked && colorMode === "dark" ? (
                <AiFillHeart
                  size={"1.5em"}
                  style={{ margin: "auto", color: "white" }}
                />
              ) : (
                <AiOutlineHeart size={"1.5em"} style={{ margin: "auto" }} />
              )}
            </Button>
            <Text fontSize={"sm"} fontWeight={"500"}>
              {Object.keys(likes1).length}
            </Text>
          </Box>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Button variant={"unstyled"}>
              <BiComment size={"1.5em"} style={{ margin: "auto" }} />
            </Button>
          </Box>
        </Box>
        <Box>
          <Button variant={"unstyled"}>
            <BiShareAlt size={"1.5em"} style={{ margin: "auto" }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Post;
