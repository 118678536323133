import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./lines/Register";
import Login from "./lines/Login";
import User from "./lines/User";
import Error from "./components/Error";
import Friend from "./lines/Friend";
import("./styles/loader.css")


function App() {

  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user" element={<User />} />
        <Route path="/friend/:id" element={<Friend/>} />
        <Route path="/error" element={<Error/>} />
      </Routes>
    </Router>
  );
}

export default App;
