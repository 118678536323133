import {
  Avatar,
  Box,
  Button,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { MdDelete } from "react-icons/md";
import { LuImagePlus } from "react-icons/lu";
import Post from "./Post";
import { useSelector } from "react-redux";
import Loader from "./Loader";

const notify = () => toast("Post created.");

const CreatePost = ({ profilePic }) => {
  let user = useSelector((state) => state.value);
  const [posts, setPosts] = useState("");

  async function reduce_image_file_size(
    base64Str,
    MAX_WIDTH = 1080,
    MAX_HEIGHT = 1080
  ) {
    let resized_base64 = await new Promise((resolve) => {
      let img = document.createElement("IMG");
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }

  function uploadData() {
    let description = document.getElementById("description");

    if (description.value !== "") {
      const data = new URLSearchParams();
      for (const pair of new FormData(document.getElementById("form"))) {
        data.append(pair[0], pair[1]);
      }

      fetch(`${process.env.REACT_APP_API_KEY}/posts`, {
        method: "post",
        body: data,
        headers: { Authorization: `Bearer ${user.token}` },
      }).then(async (resp) => {
        document.getElementById("post-btn").disabled = false;
        notify();
        let response = await resp.json();
        console.log(response);
        setPosts(response);
      });
      document.getElementById("post-btn").disabled = true;
      document.getElementById("form").reset();
      setFileName("Select picture...");
      setImage(null);
    }
  }

  useEffect(() => {
    const input = document.querySelector("#uploadImage");
    const userInfo = document.getElementById("info");

    if (input) {
      input.addEventListener("change", () => {
        validateSize();
      });
    }

    function validateSize() {
      const file = input.files[0];
      if (!file) {
        const err = new Error("No file selected");
        userInfo.textContent = err.message;
        return err;
      }

      const limit = 20000;
      const size = file.size / 1024;

      if (size > limit) {
        userInfo.style.display = "block";
        document.getElementById("post-btn").disabled = true;
        const err = new Error(`Select image less than 20MB`);
        userInfo.textContent = err.message;
        return err;
      } else {
        document.getElementById("post-btn").disabled = false;
        userInfo.style.display = "none";
        return false;
      }
    }

    let hello = async () => {
      let resizesd = await reduce_image_file_size(image);
      setImage(resizesd);
    };
    hello();
  });

  useEffect(() => {
    async function logPosts() {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/posts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      let resp = await response.json();

      setPosts(resp);
    }
    logPosts();
    // eslint-disable-next-line
  }, []);

  const borderColor = useColorModeValue("blackAlpha.300", "whiteAlpha.300");
  const [fileName, setFileName] = useState("Select picture...");
  const [image, setImage] = useState(null);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        w={"full"}
        p={"1rem"}
        boxShadow={"md"}
        borderRadius={"10px"}
        gap={"1rem"}
      >
        <form
          id="form"
          style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
        >
          <Box
            display={"flex"}
            gap={"1rem"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Avatar src={profilePic} height={'50px'} width={'50px'} />

            <input type="text" value={user.user._id} name="userId" hidden />

            <Input
              name="description"
              id="description"
              size={"lg"}
              w={"full"}
              borderRadius={"full"}
              placeholder="Write something here..."
              required
              variant={"filled"}
            />
          </Box>
          <hr />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"1.5rem"}
          >
            <Box
              p={"1rem"}
              border={"2px"}
              borderRadius={"7px"}
              borderColor={borderColor}
              display={"flex"}
              justifyContent={"space-between"}
              width={"full"}
              alignItems={"center"}
              flexWrap={'wrap'}
              overflowX={'hidden'}
            >
              <input
                type="file"
                size={"xl"}
                accept="image/*"
                className="upload-image"
                id="uploadImage"
                hidden
                onChange={({ target: { files } }) => {
                  let file = files[0];
                  var reader = new FileReader();
                  reader.onloadend = async function () {
                    setImage(reader.result);
                  };
                  reader.readAsDataURL(file);
                  if (file) {
                    setFileName(file.name);
                  }
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <span style={{ opacity: "0.8" }}>{fileName}</span>

              {image ? (
                <MdDelete
                  size={"1.5em"}
                  opacity={0.7}
                  cursor={"pointer"}
                  onClick={() => {
                    setFileName("Select picture...");
                    setImage(null);
                  }}
                />
              ) : (
                <LuImagePlus
                  size={"1.5em"}
                  opacity={0.7}
                  cursor={"pointer"}
                  onClick={() => {
                    document.querySelector(".upload-image").click();
                  }}
                />
              )}
            </Box>

            <input type="text" value={image} name="picturePath" hidden />

            <Button
              onClick={uploadData}
              id="post-btn"
              colorScheme={"orange"}
              borderRadius={"full"}
            >
              POST
            </Button>
          </Box>
        </form>
        <div
          id="info"
          style={{ alignSelf: "flex-start", color: "red", display: "none" }}
        ></div>
      </Box>
      {posts ? (
        posts.toReversed().map((value) => {
          return (
            <Post
              id={value._id}
              picturePath={value.picturePath}
              description={value.description}
              firstName={value.firstName}
              lastName={value.lastName}
              likes={value.likes}
              userId={value.userId}
              location={value.location}
              occupation={value.occupation}
              userPicturePath={value.userPicturePath}
            />
          );
        })
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CreatePost;
