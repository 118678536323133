import {
  Avatar,
  Box,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoPersonRemoveOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

const FriendList = () => {
  let user = useSelector((state) => state.value.user);
  let token = useSelector((state) => state.value.token);
  let friends = useSelector((state) => state.friendList);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getFriends() {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/users/${user._id}/friends`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let resp = await response.json();
      dispatch({
        type: "getFriends",
        payload: resp,
      });
    }
    getFriends();
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      boxShadow={"md"}
      borderRadius={"10px"}
      p={"1rem"}
      pos={{lg:"sticky", base:"unset"}}
      top={"112px"}
      overflowY={"auto"}
      className="scroll"
      order={{lg:"unset", base:"2"}}
      height={{lg:"calc(100vh - 144px)", base:"60vh"}}
    >
      <Text fontSize={"md"} fontWeight={"700"}>
        Friend List
      </Text>
      {friends.length === 0 || !friends ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          You have no friends yet.
        </div>
      ) : (
        friends.map((value) => {
          return (
            <Friend
            id={value._id}
              firstName={value.firstName}
              lastName={value.lastName}
              picturePath={value.picturePath}
              location={value.location}
            />
          );
        })
      )}
    </Box>
  );
};

const Friend = ({ id, firstName, lastName, picturePath, location }) => {
  const button = useColorModeValue("#e8ecf4", "#282c3c");
  const text = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  let user = useSelector((state) => state.value);
  const dispatch = useDispatch();

  async function toggleFriend() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}/users/${user.user._id}/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    let resp = await response.json();
    dispatch({
      type: "getFriends",
      payload: resp,
    });
    let abc123 = resp.map(({ _id }) => {
      return _id;
    });
    dispatch({
      type: "updateFriends",
      payload: abc123,
    });
  }

  return (
    <Box
      display={"flex"}
      gap={"0.5rem"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Avatar src={picturePath} height={'50px'} width={'50px'}/>
      <Box width={"full"}>
        <Text fontSize={"md"} fontWeight={"700"}>
          {firstName} {lastName}
        </Text>
        <Text fontSize={"sm"} fontWeight={"500"} color={text}>
          {location}
        </Text>
      </Box>
      <Button
        variant={"unstyled"}
        borderRadius={"full"}
        bg={button}
        onClick={toggleFriend}
      >
        <IoPersonRemoveOutline size={"1.5em"} style={{ margin: "auto" }} />
      </Button>
    </Box>
  );
};

export default FriendList;
