import {
  Button,
  HStack,
  Input,
  Text,
  VStack,
  useColorModeValue,
  useColorMode,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdDarkMode, MdDelete, MdLightMode } from "react-icons/md";
import { LuImagePlus } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function navigate123() {
    navigate('/login')
  }

  function uploadData(e) {
    e.preventDefault();
    const data = new URLSearchParams();
    for (const pair of new FormData(document.getElementById("register-form"))) {
      data.append(pair[0], pair[1]);
    }

    fetch(`${process.env.REACT_APP_API_KEY}/auth/register`, {
      method: "post",
      body: data,
    }).then(async (res) => {
      let resp = await res.json();
      dispatch({
        type: "saveUser",
        payload: resp,
      });

      if (resp.error) {
        navigate("/error");
      } else {
        navigate(`/user`);
      }
    });
  }

  async function reduce_image_file_size(
    base64Str,
    MAX_WIDTH = 1080,
    MAX_HEIGHT = 1080
  ) {
    let resized_base64 = await new Promise((resolve) => {
      let img = document.createElement("IMG");
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }

  useEffect(() => {
    const input = document.querySelector("#uploadImage");
    const userInfo = document.getElementById("info");

    if (input) {
      input.addEventListener("change", () => {
        validateSize();
      });
    }

    function validateSize() {
      const file = input.files[0];
      if (!file) {
        const err = new Error("No file selected");
        userInfo.textContent = err.message;
        return err;
      }

      const limit = 20000;
      const size = file.size / 1024;

      if (size > limit) {
        userInfo.style.display = "block";
        document.getElementById("reg-btn").disabled = true;
        const err = new Error(`Select image less than 20MB`);
        userInfo.textContent = err.message;
        return err;
      } else {
        document.getElementById("reg-btn").disabled = false;
        userInfo.style.display = "none";
        return false;
      }
    }

    let hello = async () => {
      let resizesd = await reduce_image_file_size(image);
      setImage(resizesd);
    };
    hello();
  });

  const borderColor = useColorModeValue("blackAlpha.300", "whiteAlpha.300");
  const orange = useColorModeValue("orange.500", "orange.200");
  const { colorMode, toggleColorMode } = useColorMode();

  const [fileName, setFileName] = useState("Upload Profile Picture");
  const [image, setImage] = useState(null);

  // const [user, setUser] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   password: "",
  //   friends: "",
  //   location: "",
  //   occupation: "",
  // });

  // let name, value;

  // const handleInputs = (e) => {
  //   name = e.target.name;
  //   value = e.target.value;

  //   setUser({ ...user, [name]: value });
  // };

  return (
      <Box
        w={'full'}
        minH={'100vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        gap={'1rem'}
        pt={'1rem'}
        pb={'2rem'}
      >
        <Button m={"1rem"} onClick={toggleColorMode} w={'fit-content'} alignSelf={'center'}>
        {colorMode === "light" ? (
          <MdDarkMode size={"1.5em"} />
        ) : (
          <MdLightMode size={"1.5em"} />
        )}
      </Button>
        <Text
          fontSize={{ lg: "4xl", base: "2xl" }}
          px={{ lg: "unset", base: "1rem" }}
          textAlign={"center"}
          color={orange}
          fontWeight={"700"}
          letterSpacing={"2px"}
        >
          Welcome To <span style={{ color: "#3182CE" }}>LinkLyfe</span> <br />{" "}
          The Community Of Friends
        </Text>
        <form onSubmit={uploadData} id="register-form">
          <VStack
            width={{ "2xl": "50%", md: "70%", base: "100%" }}
            margin={"auto"}
            p={"2rem"}
            borderRadius={"10px"}
            gap={"1.5rem"}
            boxShadow={{md:"md", base:"unset"}}
          >
            <HStack w={"full"} gap={"1.5rem"}>
              <Input
                variant={"unstyled"}
                px={"1rem"}
                py={"0.5rem"}
                placeholder="First Name"
                // value={user.firstName}
                name="firstName"
                border={"2px"}
                borderColor={borderColor}
              />
              <Input
                variant={"unstyled"}
                px={"1rem"}
                py={"0.5rem"}
                placeholder="Last Name"
                // value={user.lastName}
                name="lastName"
                border={"2px"}
                borderColor={borderColor}
              />
            </HStack>
            <Input
              variant={"unstyled"}
              px={"1rem"}
              py={"0.5rem"}
              placeholder="Location"
              // value={user.location}
              name="location"
              border={"2px"}
              borderColor={borderColor}
            />
            <Input
              variant={"unstyled"}
              px={"1rem"}
              py={"0.5rem"}
              placeholder="Occupation"
              // value={user.occupation}
              name="occupation"
              border={"2px"}
              borderColor={borderColor}
            />
            <Box
              p={"1rem"}
              border={"2px"}
              borderRadius={"7px"}
              borderColor={borderColor}
              display={"flex"}
              justifyContent={"space-between"}
              width={"full"}
              alignItems={"center"}
            >
              <input
                type="file"
                size={"xl"}
                accept="image/*"
                className="upload-image"
                id="uploadImage"
                hidden
                onChange={({ target: { files } }) => {
                  let file = files[0];
                  var reader = new FileReader();
                  reader.onloadend = function () {
                    setImage(reader.result);
                  };
                  reader.readAsDataURL(file);
                  if (file) {
                    setFileName(file.name);
                  }
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <span style={{ opacity: "0.8" }}>{fileName}</span>

              {image ? (
                <MdDelete
                  size={"1.5em"}
                  opacity={0.7}
                  cursor={"pointer"}
                  onClick={() => {
                    setFileName("Upload Profile Picture");
                    setImage(null);
                  }}
                />
              ) : (
                <LuImagePlus
                  size={"1.5em"}
                  opacity={0.7}
                  cursor={"pointer"}
                  onClick={() => {
                    document.querySelector(".upload-image").click();
                  }}
                />
              )}
            </Box>
            <div
              id="info"
              style={{ alignSelf: "flex-start", color: "red", display: "none" }}
            ></div>

            <Input
              variant={"unstyled"}
              px={"1rem"}
              py={"0.5rem"}
              placeholder="Email"
              // value={user.email}
              name="email"
              border={"2px"}
              borderColor={borderColor}
              required
            />
            <input type="text" value={image} name="picturePath" hidden />

            <Input
              variant={"unstyled"}
              type="password"
              px={"1rem"}
              py={"0.5rem"}
              placeholder="Password"
              name="password"
              border={"2px"}
              borderColor={borderColor}
            />
            <Button
              type="submit"
              colorScheme="orange"
              w={"full"}
              py={"1.5rem"}
              id="reg-btn"
            >
              REGISTER
            </Button>
            <Button variant={'link'} style={{ fontWeight: "500" }} color={orange} onClick={navigate123}>
              Already have an account? Login here.
            </Button>
            <Text>© 2023 Subham Chatterjee</Text>
          </VStack>
        </form>
      </Box>
  );
};

export default Register;
