import React from 'react'

const Loader = () => {
  return (
    <div className='loader-div'>
<div className='loader'></div>
    </div>
    
  )
}

export default Loader